import { DateTime } from "luxon";
import { useState } from "react";
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis
} from "recharts";

import styles from "./MinimalGraph.module.scss";

import colors from "~/styles/colors";

type MinimalBarGraphProps<T> = {
  data: T[];
  keys: { label: string; key: keyof T }[];
};

const MinimalBarGraph = <T extends { date: string }>({
  data,
  keys
}: MinimalBarGraphProps<T>) => {
  const axisLine = { stroke: colors.navyBlue20, strokeWidth: 2 };

  const [selectedKey, setSelectedKey] = useState(keys[0].key.toString());

  return (
    <div className={styles.wrapper}>
      <div className={styles.keys}>
        {keys.map((key, index) => (
          <button
            key={`line-key-${index}-${key.key as string}`}
            onClick={() => setSelectedKey(key.key.toString())}
            className={
              selectedKey === key.key.toString()
                ? styles.activeButton
                : styles.button
            }
          >
            {key.label}
          </button>
        ))}
      </div>
      <ResponsiveContainer height={250}>
        <ComposedChart
          data={data}
          barSize={data.length <= 7 ? 16 : 8}
          margin={{ top: 20, bottom: 23 }}
        >
          <CartesianGrid vertical={false} stroke={colors.navyBlue10} />
          <XAxis
            angle={-45}
            textAnchor="end"
            dataKey="date"
            axisLine={axisLine}
            tickLine={false}
            tickFormatter={(date) =>
              DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT)
            }
            tick={{ fontSize: 12 }}
            tickSize={0}
          />
          <YAxis
            dataKey={selectedKey}
            axisLine={axisLine}
            tick={{
              fill: colors.navyBlue50
            }}
            tickLine={false}
            interval={0}
            allowDecimals={false}
          />
          <Bar dataKey={selectedKey} radius={[4, 4, 0, 0]}>
            {data.map((index) => {
              return <Cell key={`cell-${index}`} fill={colors.lightBlue40} />;
            })}
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MinimalBarGraph;
