import { DateTime } from "luxon";

import api from "~/api/api";
import { displaySuccessToast } from "~/helpers/toast/displayToast";
import { t } from "~/i18n";
import { ScoreMeal } from "~/typing/sidekickTypes";

export const getFoodJournals = async ({
  programId,
  locale,
  userId,
  startDate,
  endDate,
  getMostRecent,
  page = 1
}: {
  programId: string;
  locale: string;
  userId: string;
  startDate: DateTime;
  endDate: DateTime;
  getMostRecent?: boolean;
  page?: number;
}): Promise<
  | {
      scoremeals: ScoreMeal[];
      page: number;
      pageCount: number;
      pageSize: number;
    }
  | undefined
> => {
  try {
    let path = `/coach/programs/${programId}/locales/${locale}/users/${userId}/scoremeals?page=${page}`;
    if (!getMostRecent) {
      path += `&startDate=${startDate
        .minus({ days: 1 })
        .toISODate()}&endDate=${endDate.plus({ days: 1 }).toISODate()}`;
    }
    const journalsRes = await api.get(path);

    const journalsData = journalsRes?.data;

    return journalsData;
  } catch {
    return undefined;
  }
};

export const updateFoodJournal = async ({
  programId,
  locale,
  userId,
  payload
}: {
  programId: string;
  locale: string;
  userId: string;
  payload: {
    id: string;
    scoreId: string;
    coachReview?: string;
    coachHealthyness?: number;
    coachPortionSize?: number;
  };
}) => {
  try {
    const path = `/coach/programs/${programId}/locales/${locale}/users/${userId}/scoremeals/${payload.id}`;

    const updateRes = await api.put(path, payload);

    if (updateRes?.res?.ok) {
      displaySuccessToast({
        message: t("performance.journals.review.success")
      });
    }

    return updateRes?.data;
  } catch {
    return undefined;
  }
};
