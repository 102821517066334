import { DateTime } from "luxon";

import api from "~/api/api";
import { displayErrorToast } from "~/helpers/toast/displayToast";
import { t } from "~/i18n";

export const saveBaseWeight = async (
  weight: number,
  programCatalogItemId: string,
  locale: string,
  userId: string
) => {
  try {
    const path = `/coach/programcatalogitems/${programCatalogItemId}/locales/${locale}/users/${userId}/userbaseweight`;

    const postData = { value: weight };

    const res = await api.post(path, postData);
    if (res?.res?.ok) {
      return res?.data;
    } else {
      displayErrorToast({
        message: t("errors.save", { resource: t("user.health.baseWeight") })
      });
    }
  } catch {
    displayErrorToast({
      message: t("errors.save", { resource: t("user.health.baseWeight") })
    });
  }
};

export const getWeightDataForUser = async (
  programId: string,
  locale: string,
  userId: string,
  weightMissionId: string,
  startDate: DateTime,
  endDate: DateTime
) => {
  try {
    const res = await api.get(
      `/coach/programs/${programId}/locales/${locale}/users/${userId}/scores?missionId=${weightMissionId}&startDate=${startDate.toISO()}&endDate=${endDate.toISO()}`
    );

    if (res?.res?.ok) {
      return res?.data?.items;
    } else {
      displayErrorToast({
        message: t("errors.fetch", { resource: t("weightHistory.weightData") })
      });
    }
  } catch {
    displayErrorToast({
      message: t("errors.fetch", { resource: t("weightHistory.weightData") })
    });
  }
};
