import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import styles from "./NavProfile.module.scss";

import config from "../../config";
import { UserPrivileges } from "../../constants/privileges";
import EditEmailFrequency from "../../pages/user/information/modals/editEmailFrequency/EditEmailFrequency";

import SettingsIcon from "~/assets/cog.svg";
import LogoutIcon from "~/assets/logout.svg";
import ProfileIcon from "~/assets/user.svg";
import Avatar from "~/components/avatar/Avatar";
import useOnClickOutside from "~/hooks/useOnClickOutside";
import { EnrichedAuthenticatedUser } from "~/typing/sidekickTypes";

type DropdownItem = {
  link?: string;
  text: string;
  icon: string;
  onClick?: () => void;
};

type NavProfileProps = {
  user: EnrichedAuthenticatedUser;
};

const NavProfile = ({ user }: NavProfileProps) => {
  const { t } = useTranslation();

  const [providerSettingsModalOpen, setProviderSettingsModalOpen] = useState(
    false
  );
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => {
    if (showDropdown && !providerSettingsModalOpen) {
      setShowDropdown(false);
    }
  });

  const getDropDownItems = (): DropdownItem[] => {
    switch (parseInt(user?.privileges)) {
      case UserPrivileges.Compliance:
        return [
          {
            link: "/me",
            text: t("header.myPage", "My page"),
            icon: ProfileIcon
          }
        ];
      case UserPrivileges.Provider:
        return config.showProviderSettings
          ? [
              {
                onClick: () => setProviderSettingsModalOpen(true),
                text: t("header.settings", "Settings"),
                icon: SettingsIcon
              }
            ]
          : [];
      default:
        return [
          {
            link: "/me",
            text: t("header.myPage", "My page"),
            icon: ProfileIcon
          }
        ];
    }
  };

  const navItems = getDropDownItems()
    .concat([
      {
        link: "/logout",
        text: t("header.logout", "Log out"),
        icon: LogoutIcon
      }
    ])
    .map(({ link, text, icon, onClick }) => {
      return (
        <li key={text} className={styles.NavProfile__NavItem}>
          {link ? (
            <Link to={link}>
              <img src={icon} alt={text} className={styles.NavProfile__Icon} />
              <span>{text}</span>
            </Link>
          ) : onClick ? (
            <button onClick={onClick}>
              <img src={icon} alt={text} className={styles.NavProfile__Icon} />
              <span>{text}</span>
            </button>
          ) : null}
        </li>
      );
    });

  return (
    <div style={{ position: "relative" }} ref={dropdownRef}>
      <button
        className={styles.NavProfile__User}
        onClick={() => setShowDropdown(!showDropdown)}
        data-testid="nav-profile-dropdown"
      >
        <Avatar
          user={user}
          size="sm"
          className={styles.NavProfile__User__Avatar}
        />
        <p>{user?.displayName}</p>
      </button>
      {showDropdown && (
        <div className={styles.NavProfile__ProfileDropdown}>
          <ul className={styles.NavProfile}>
            <div className={styles.NavProfile__Notch} />
            {navItems}
          </ul>
        </div>
      )}
      {providerSettingsModalOpen && (
        <EditEmailFrequency
          onClose={() => setProviderSettingsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default NavProfile;
